import React, { useEffect, useState } from 'react';
import { useAction } from '@/Actions/useAction';
import { useSelector } from 'react-redux';

// selectors
import {
  selectUIMode,
  selectFeatures,
  selectUIModeOptionsMenuExpanded,
  selectIsPreviewView,
} from '@/Redux/Slices/UISlice';
import { selectSelectedGroupIds } from '@/Redux/Slices/SelectionSlice';

// actions
import UIModeAction from '@/Actions/UIMode';
import SetSelectionAction from '@/Actions/SetSelection';

// components
import ModeSelectionTab from './ModeSelectionTab';

// menus
import DesignModeOptionMenu from './OptionMenus/DesignModeOptionsMenu';
import PlanModeOptionsMenu from './OptionMenus/PlanModeOptionsMenu';
import { entitlements } from '@/Helpers/Entitlements';
import { Mode as ModeType } from '@/@types/shaper-types';

type UIModeActionFunc = 'toDefault' | 'toPlan' | 'toReview';

export type Mode = {
  name: string;
  modes: ModeType[];
  icon: string;
  menu: React.JSX.ElementType | null;
  activation: UIModeActionFunc;
  feature: string;
  visible?: boolean;
  i18nKey: string;
  selected?: boolean;
};

const ModeSelectionMenuContext = React.createContext<boolean | null>(null);
export const useModeSelectionMenuContext = () =>
  React.useContext(ModeSelectionMenuContext);

export default function ModeSelectionMenu() {
  // selectors
  const currentMode = useSelector(selectUIMode);
  const selection = useSelector(selectSelectedGroupIds);
  const expanded = useSelector(selectUIModeOptionsMenuExpanded);
  const isPreviewView = useSelector(selectIsPreviewView);

  // actions
  const uiModeAction = useAction(UIModeAction);
  const selectionAction = useAction(SetSelectionAction);
  const [disabled, setDisabled] = useState(false);

  // modes
  const MODES: Mode[] = [
    {
      name: 'Design',
      modes: ['default', 'anchor-selection', 'text-editor'],
      icon: 'mode-design',
      menu: DesignModeOptionMenu,
      activation: 'toDefault',
      feature: entitlements.DESIGN_MODE,
      i18nKey: 'design',
    },

    {
      name: 'Plan',
      modes: ['plan'],
      icon: 'mode-plan',
      menu: PlanModeOptionsMenu,
      activation: 'toPlan',
      feature: entitlements.PLAN_MODE,
      visible: true,
      i18nKey: 'plan',
    },

    {
      name: 'Review',
      modes: ['review'],
      icon: 'mode-review',
      menu: null,
      activation: 'toReview',
      feature: entitlements.REVIEW_MODE,
      visible: true,
      i18nKey: 'review',
    },
  ];

  // actions
  const selected = MODES.find((mode) => mode.modes.includes(currentMode));

  function onSwitchToPlanMode() {
    if (!selection.length) {
      selectionAction.selectAllPaths();
    }
  }

  // toggles the mode expansion
  function onToggleMode() {
    if (selected?.menu) {
      uiModeAction.toggleOptionsMenu(!expanded);
    }
  }

  // deactivates the mode menu
  function onActivateMode(mode: UIModeActionFunc) {
    if (/plan/i.test(mode)) {
      onSwitchToPlanMode();
    }

    uiModeAction[mode]();
    uiModeAction.toggleOptionsMenu(false);
  }

  function renderSelectedMenu() {
    if (selected) {
      const { menu: Menu } = selected;
      if (Menu) {
        return <Menu />;
      }
    }
  }

  useEffect(() => {
    if (isPreviewView) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [isPreviewView]);

  return (
    <div
      className='mode-selection-menu'
      role='menu'
      data-cy='mode-selection-menu'
    >
      <ModeSelectionMenuContext.Provider value={disabled}>
        <div className='mode-selection-menu--modes'>
          <div className='mode-selection-menu--modes--container'>
            {MODES.filter(
              (mode) => selectFeatures(mode.feature) || !!mode.visible
            ).map((mode) => (
              <ModeSelectionTab
                {...mode}
                key={mode.name}
                selected={mode === selected}
                expanded={expanded}
                hasMenu={!!mode.menu}
                onActivateMode={() => onActivateMode(mode.activation)}
                onToggleMode={onToggleMode}
                dataCy={mode.i18nKey}
              />
            ))}
          </div>
        </div>

        {expanded && (
          <div
            className='mode-selection-menu--options'
            data-cy='mode-menu-options'
          >
            {renderSelectedMenu()}
          </div>
        )}
      </ModeSelectionMenuContext.Provider>
    </div>
  );
}
